import { Injectable } from '@angular/core'
import { Sort } from '@angular/material/sort'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'
import { Filters } from '../../module/dashboard/tabs/economy-tab/dugnads/enums/filters'
import { DugnadsResponse } from '../../module/dashboard/tabs/economy-tab/dugnads/interfaces/dugnads-response'
import { DugnadPayload } from '../../module/dashboard/tabs/economy-tab/dugnads/payment-summary/interfaces/dugnad-payload'
import { ExpectedIncomePayload } from '../../module/dashboard/tabs/economy-tab/dugnads/payment-summary/interfaces/expected-income-payload'
import { ExpectedIncomeResponse } from '../../module/dashboard/tabs/economy-tab/dugnads/payment-summary/interfaces/expected-income-response'
import { Columns } from '../enums/table/columns'
import { ResponseCollection } from '../interfaces/http/response-collection'
import { RequestService, UtilsService } from '../services'
import { DugnadResponse } from '../../module/dashboard/tabs/economy-tab/dugnads/dugnad-details/interfaces/dugnad-response'
import { FilterNames } from '../../module/dashboard/tabs/economy-tab/enums/filter-names'
import { Filtering as OrdersFilters } from '../../module/dashboard/tabs/economy-tab/dugnads/dugnad-details/orders/enums/filtering'
import { OrderResponse } from '../../module/dashboard/tabs/economy-tab/dugnads/dugnad-details/orders/interfaces/order-response'
import {
  ConfirmationStatusFilterValues,
  OrderStatusFilterValues
} from '../../module/dashboard/tabs/economy-tab/dugnads/dugnad-details/orders/enums/filter-values'
import { Options } from '../classes/http/options'
import { DugnadGroupsResponse } from '../../module/dashboard/tabs/economy-tab/dugnad-groups/interfaces/dugnad-groups-response'
import { DugnadOrderPayload } from '../../module/dashboard/tabs/economy-tab/edit-dugnad-order/interfaces/dugnad-order-payload'
import { PublishStatus } from '../../module/dashboard/tabs/economy-tab/dugnads/enums/publish-status'
import { ReminderType } from '../../module/dashboard/tabs/economy-tab/enums/reminder-type'
import { DugnadReminderHistoryResponse } from '../../module/dashboard/tabs/economy-tab/dugnad-reminder-history/interfaces/dugnad-reminder-history-response'
import { OrderStatus } from '../../module/dashboard/tabs/economy-tab/interfaces/order-status'
import { DugnadOrder } from '../../module/dashboard/tabs/economy-tab/interfaces/dugnad-order'

@Injectable({ providedIn: 'root' })
export class DugnadsService {
  private apiOrganizations = environment.apiUrl + 'web/organizations/'

  constructor(
    private requestService: RequestService,
    private utils: UtilsService
  ) {}

  private getSortingKey(sorting: Sort | null): string {
    let sortingKey: string = ''

    if (sorting) {
      switch (sorting.active) {
        case Columns.DugnadName:
          sortingKey = 'title'
          break
        case Columns.Status:
          sortingKey = 'publish_status'
          break
        case Columns.CreatedFor:
          sortingKey = 'created_for_number_of_groups'
          break
        case Columns.Name:
          sortingKey = 'full_name'
          break
        case Columns.NumberOfItems:
          sortingKey = 'items_number'
          break
        case Columns.ConfirmationStatus:
          sortingKey = 'is_confirmed'
          break
        case Columns.OrderStatus:
          sortingKey = 'order_status'
          break
        case Columns.ConfirmationDueDate:
          sortingKey = 'confirmation_due_date'
          break
        case Columns.PaymentStatus:
          sortingKey = 'paid_status'
          break
        case Columns.LastReminder:
          sortingKey = 'latest_reminder_date'
          break
        case Columns.NetAmount:
          sortingKey = 'net_amount'
          break
        case Columns.Price:
          sortingKey = 'gross_amount'
          break
        case Columns.TransactionFee:
          sortingKey = 'total_fee'
          break
        case Columns.DueDate:
          sortingKey = 'due_date'
          break
        case Columns.PaymentDueDate:
          sortingKey = 'payment_due_date'
          break
        case Columns.PaymentDate:
          sortingKey = 'charge_made'
          break
      }

      if (sorting.direction === 'desc') {
        sortingKey = `-${sortingKey}`
      }
    }

    return sortingKey
  }

  // TODO: move to utils and unify with other places we use this approach
  private composeQueryString(condition: boolean, query: string): string {
    return condition ? query : ''
  }

  checkDugnadsPermissions(groupId: number): Observable<Options> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/`
    return this.requestService.options(url, { version: 2 })
  }

  checkDugnadPermissions(groupId: number, dugnadId: number): Observable<Options> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/${dugnadId}/`
    return this.requestService.options(url, { version: 2 })
  }

  getDugnads(
    groupId: number,
    options: { filters: Filters; sorting: Sort | null; page: number; search: string }
  ): Observable<ResponseCollection<DugnadsResponse>> {
    let filterQuery = ''

    switch (options.filters) {
      case Filters.Upcoming:
        filterQuery = `&publish_status=${PublishStatus.Upcoming}`
        break
      case Filters.Ongoing:
        filterQuery = `&publish_status=${PublishStatus.Ongoing}`
        break
      case Filters.Past:
        filterQuery = `&publish_status=${PublishStatus.Past}`
        break
    }

    const sortQuery = this.composeQueryString(!!options.sorting, `&ordering=${this.getSortingKey(options.sorting)}`)
    const searchQuery = this.composeQueryString(!!options.search, `&search=${options.search}`)
    const queryParams = `?page=${options.page}${filterQuery}${sortQuery}${searchQuery}`
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/${queryParams}`

    return this.requestService.get(url, { version: 2 })
  }

  getExpectedIncome(groupId: number, payload: ExpectedIncomePayload): Observable<ExpectedIncomeResponse> {
    const url = `${this.apiOrganizations}groups/${groupId}/expected-income/`
    return this.requestService.post(url, payload)
  }

  createDugnad(groupId: number, payload: DugnadPayload): Observable<any> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/`
    return this.requestService.post(url, payload, { version: 2 })
  }

  getDugnadGroups(groupId: number, dugnadId: number): Observable<DugnadGroupsResponse> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/${dugnadId}/groups/`
    return this.requestService.get(url)
  }

  getDugnad(groupId: number, dugnadId: number): Observable<DugnadResponse> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/${dugnadId}/`
    return this.requestService.get(url, { version: 2 })
  }

  updateDugnad(
    groupId: number,
    dugnadId: number,
    payload: {
      publish_user_payments_date?: string
      payment_deadline?: string | null
      pick_up_date?: string
      pick_up_venue?: number | null
      pick_up_location?: string | null
      pick_up_managers?: number[]
    }
  ): Observable<any> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/${dugnadId}/`
    return this.requestService.patch(url, payload, { version: 2 })
  }

  exportOrders(groupId: number, dugnadId: number): Observable<Blob> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/${dugnadId}/orders/export/`
    return this.requestService.getExcelFile(url)
  }

  getDugnadOrders(
    dugnadId: number,
    groupId: number,
    options: {
      searchQuery: string
      filters: OrdersFilters
      sorting: Sort | null
      page: number
    }
  ): Observable<ResponseCollection<OrderResponse>> {
    const searchQuery = this.composeQueryString(!!options.searchQuery, `&search=${options.searchQuery}`)

    let filterQuery = ''

    switch (options.filters[FilterNames.ConfirmationStatus]) {
      case ConfirmationStatusFilterValues.Confirmed:
        filterQuery += `&is_confirmed=True`
        break
      case ConfirmationStatusFilterValues.NotConfirmed:
        filterQuery += `&is_confirmed=False`
        break
    }

    switch (options.filters[FilterNames.OrderStatus]) {
      case OrderStatusFilterValues.BoughtOut:
        filterQuery += `&order_status=${OrderStatus.BoughtOut}`
        break
      case OrderStatusFilterValues.PickedUp:
        filterQuery += `&order_status=${OrderStatus.PickedUp}`
        break
      case OrderStatusFilterValues.NotPickedUp:
        filterQuery += `&order_status=${OrderStatus.NotSet}`
        break
    }

    if (options.filters[FilterNames.PaidStatus].length) {
      const paidStatusFilterQueryList = options.filters[FilterNames.PaidStatus]
        .map((status) => this.utils.getPaidStatusQueryKey(status))
        .join(',')
      filterQuery += `&paid_statuses=${paidStatusFilterQueryList}`
    }

    if (options.filters[FilterNames.ConfirmationDueDateFrom]) {
      filterQuery += `&confirmation_due_date_after=${options.filters[FilterNames.ConfirmationDueDateFrom]}`
    }

    if (options.filters[FilterNames.ConfirmationDueDateTo]) {
      filterQuery += `&confirmation_due_date_before=${options.filters[FilterNames.ConfirmationDueDateTo]}`
    }

    if (options.filters[FilterNames.PaymentDueDateFrom]) {
      filterQuery += `&payment_due_date_after=${options.filters[FilterNames.PaymentDueDateFrom]}`
    }

    if (options.filters[FilterNames.PaymentDueDateTo]) {
      filterQuery += `&payment_due_date_before=${options.filters[FilterNames.PaymentDueDateTo]}`
    }

    if (options.filters[FilterNames.ChargeDateFrom]) {
      filterQuery += `&charge_made_after=${options.filters[FilterNames.ChargeDateFrom]}`
    }

    if (options.filters[FilterNames.ChargeDateTo]) {
      filterQuery += `&charge_made_before=${options.filters[FilterNames.ChargeDateTo]}`
    }

    if (options.filters[FilterNames.LastReminderDateFrom]) {
      filterQuery += `&latest_reminder_date_after=${options.filters[FilterNames.LastReminderDateFrom]}`
    }

    if (options.filters[FilterNames.LastReminderDateTo]) {
      filterQuery += `&latest_reminder_date_before=${options.filters[FilterNames.LastReminderDateTo]}`
    }

    const pageQuery = `page=${options.page}`
    const sortQuery = this.composeQueryString(!!options.sorting, `&ordering=${this.getSortingKey(options.sorting)}`)
    const queryParams = `${pageQuery}${searchQuery}${filterQuery}${sortQuery}`
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/${dugnadId}/orders/?${queryParams}`

    return this.requestService.get(url, { version: 2 })
  }

  getDugnadOrder(id: number, groupId: number): Observable<DugnadOrder> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/orders/${id}/`
    return this.requestService.get(url)
  }

  updateOrder(orderId: number, groupId: number, payload: DugnadOrderPayload): Observable<any> {
    const url = `${this.apiOrganizations}groups/${groupId}/dugnads/orders/${orderId}/`
    return this.requestService.patch(url, payload)
  }

  getDugnadReminders(orderId: number, groupId: number): Observable<ResponseCollection<DugnadReminderHistoryResponse>> {
    return this.requestService.get(`${this.apiOrganizations}groups/${groupId}/user-order/${orderId}/reminders/`)
  }

  sendReminder(
    orderId: number,
    groupId: number,
    payload: { reminder_type: ReminderType; message: string; send_sms_notification?: boolean }
  ): Observable<any> {
    const url = `${this.apiOrganizations}groups/${groupId}/user-order/${orderId}/reminders/`
    return this.requestService.post(url, payload)
  }
}
