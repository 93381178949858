<app-overlay [loading]="loading" appFloatingElement (close)="onClose()">
  <div class="overlay__buttons">
    <app-button theme="transparent" class="back-button" (click)="onClose()">
      {{ 'action.back' | translate }}
    </app-button>
    <app-button class="next-button" [disabled]="userPaymentForm.invalid" (click)="saveUserPayment()">
      {{ 'action.save' | translate }}
    </app-button>
  </div>

  <div class="overlay__header">
    <h2>{{ 'payment.edit_user_payment_for' | translate }} {{ user.first_name }} {{ user.last_name }}</h2>
    <p class="sub-header ns-text small">{{ paymentName }}</p>
  </div>

  <div class="overlay__body overlay__body--default-width">
    @if (userPayment && userPayment.is_mandatory) {
      @if (paidStatus === PaidStatus.Declined) {
        <app-info-block type="warning" [title]="'economy.payment_is_mandatory_for_joining_the_group_2' | translate">
          {{
            'economy.payment_is_mandatory_for_joining_the_group_description_2' | translate: {
              name: this.userPayment.user.first_name + ' ' + this.userPayment.user.last_name,
              group: this.userPayment.payment.group_name
            }
          }}
        </app-info-block>    
      } @else {
        <app-info-block type="warning" [title]="'economy.payment_is_mandatory_for_joining_the_group' | translate">
          <p>
            {{
              'economy.payment_is_mandatory_for_joining_the_group_description' | translate: {
                name: this.userPayment.user.first_name + ' ' + this.userPayment.user.last_name,
                group: this.userPayment.payment.group_name
              }
            }}
          </p>
          <p>{{ 'economy.payment_is_mandatory_for_joining_the_group_description_3' | translate }}</p>
          <p>{{ 'economy.payment_is_mandatory_for_joining_the_group_description_4' | translate }}</p>
        </app-info-block>
      }
    }

    <form [formGroup]="userPaymentForm" class="ns-form ns-light">
      <mat-form-field *ngIf="paymentType !== PaymentTypes.EventPayment">
        <mat-label>{{ 'economy.payment_deadline' | translate }}</mat-label>
        <input matInput type="date" formControlName="dueDate" />
        <mat-icon matPrefix>calendar_today</mat-icon>
      </mat-form-field>

      <div *ngIf="paymentType === PaymentTypes.MembershipFee" class="ns-membership-fee-price">
        <p class="ns-price-categories-header">
          <span class="ns-subtitle">{{ 'economy.price_options' | translate }}</span>
          <app-loader *ngIf="isLoadingPriceCategories" [static]="true" [size]="20"></app-loader>
        </p>

        <app-expand-y *ngIf="priceCategories" class="ns-price-categories-wrapper">
          <div class="ns-price-categories-description">
            <p *ngIf="priceCategories.length === 1" class="ns-text small">
              {{
                'payment.user_payment_price_categories_description'
                  | translate : { firstName: user.first_name, lastName: user.last_name }
              }}
            </p>
            <p *ngIf="priceCategories.length > 1" class="ns-text small">
              {{
                'payment.user_payment_price_categories_description_2'
                  | translate : { firstName: user.first_name, lastName: user.last_name }
              }}
            </p>
            <p class="ns-text small">{{ 'economy.nif_minimum_price_description' | translate }}</p>
          </div>

          <div
            class="ns-price-categories"
            [class.ns-disabled]="isSettingCustomPrice || userPaymentForm.value.status !== PaidStatus.NotPaid"
          >
            <div *ngFor="let category of priceCategories" class="ns-price-category">
              <div class="ns-category-name-wrapper ns-subtitle">
                <span class="ns-category-name">{{ category.name }}</span>
                <span class="ns-price">{{ category.gross_amount | currency }}</span>
              </div>
              <div class="ns-category-details">
                <div class="ns-category-restrictions">
                  <p
                    *ngIf="
                      (category.min_age | isNumber) &&
                      (category.max_age | isNumber) &&
                      category.min_age !== category.max_age
                    "
                  >
                    {{ 'economy.from_age_to_age' | translate : { ageFrom: category.min_age, ageTo: category.max_age } }}
                  </p>
                  <p
                    *ngIf="
                      (category.min_age | isNumber) &&
                      (category.max_age | isNumber) &&
                      category.min_age === category.max_age
                    "
                  >
                    {{ 'economy.for_age' | translate : { age: category.min_age } }}
                  </p>
                  <p *ngIf="(category.min_age | isNumber) && !(category.max_age | isNumber)">
                    <ng-container *ngIf="category.min_age === minAge">
                      {{ 'economy.all_ages' | translate }}
                    </ng-container>
                    <ng-container *ngIf="category.min_age !== minAge">
                      {{ 'economy.above_age' | translate : { age: category.min_age - 1 } }}
                    </ng-container>
                  </p>
                  <p *ngIf="!(category.min_age | isNumber) && (category.max_age | isNumber)">
                    <ng-container *ngIf="category.max_age === maxAge">
                      {{ 'economy.all_ages' | translate }}
                    </ng-container>
                    <ng-container *ngIf="category.max_age !== maxAge">
                      {{ 'economy.under_age' | translate : { age: category.max_age + 1 } }}
                    </ng-container>
                  </p>
                  <p *ngIf="category.allowed_user_type | isNumber" [ngSwitch]="category.allowed_user_type">
                    <ng-container *ngSwitchCase="EconomyUserType.Admins">
                      {{ 'economy.for_group_type' | translate }} {{ 'user_types.admins' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="EconomyUserType.Players">
                      {{ 'economy.for_group_type' | translate }} {{ 'user_types.players' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="EconomyUserType.AssociateMembers">
                      {{ 'economy.for_group_type' | translate }} {{ 'user_types.associate_members' | translate }}
                    </ng-container>
                  </p>
                </div>
                <div class="ns-category-price-description">
                  <p
                    [innerHTML]="
                      'economy.including_transaction_fee' | translate : { fee: category.total_fee | currency }
                    "
                  ></p>
                  <p>
                    {{ 'economy.net_amount' | translate }}: <span>{{ category.net_amount | currency }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p class="ns-divider ns-subtitle" [class.ns-disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid">
            {{ 'miscellaneous.or' | translate }}
          </p>

          <app-button
            *ngIf="!isSettingCustomPrice"
            theme="dark"
            class="ns-set-custom-price"
            [disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
            (click)="toggleCustomPrice(true)"
          >
            + {{ 'payment.set_custom_price' | translate }}
          </app-button>

          <ng-container *ngIf="isSettingCustomPrice">
            <div
              class="ns-custom-price-header"
              [class.ns-disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
            >
              <span class="ns-subtitle">{{ 'payment.custom_price' | translate }}</span>
              <button
                class="ns-close-custom-price"
                mat-icon-button
                icon="clear"
                [disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
                (click)="toggleCustomPrice(false)"
              ></button>
            </div>
            <div class="ns-field-with-switcher">
              <mat-form-field class="ns-no-gap">
                <mat-label *ngIf="userPaymentForm.controls.includeTransactionFee.value">
                  {{ 'economy.price' | translate }}
                </mat-label>
                <mat-label *ngIf="!userPaymentForm.controls.includeTransactionFee.value">
                  {{ 'economy.net_amount' | translate }}
                </mat-label>
                <input matInput type="number" formControlName="customPrice" autocomplete="off" />
              </mat-form-field>

              <mat-slide-toggle formControlName="includeTransactionFee" class="ns-switcher-field">
                <p class="bold">{{ 'miscellaneous.include_transaction_fee' | translate }}</p>
              </mat-slide-toggle>
            </div>

            <app-price
              class="ns-membership-fee-amount"
              [class.ns-disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
              [amount]="userPaymentForm.controls.customPrice.value"
              [includeTransactionFee]="userPaymentForm.controls.includeTransactionFee.value"
              [initialValues]="
                customPriceCategory && {
                  netAmount: customPriceCategory.net_amount,
                  transactionFee: customPriceCategory.total_fee,
                  totalAmount: customPriceCategory.gross_amount
                }
              "
            ></app-price>
          </ng-container>
        </app-expand-y>
      </div>

      <ng-container *ngIf="paymentType === PaymentTypes.TrainingFee || paymentType === PaymentTypes.RegularPayment">
        <div class="ns-field-with-switcher">
          <mat-form-field class="ns-no-gap">
            <mat-label *ngIf="userPaymentForm.value.includeTransactionFee">
              {{ 'economy.price' | translate }}
            </mat-label>
            <mat-label *ngIf="!userPaymentForm.value.includeTransactionFee">
              {{ 'economy.net_amount' | translate }}
            </mat-label>
            <input matInput type="number" formControlName="amount" autocomplete="off" />
          </mat-form-field>

          <mat-slide-toggle formControlName="includeTransactionFee" class="ns-switcher-field">
            <p class="bold">{{ 'miscellaneous.include_transaction_fee' | translate }}</p>
          </mat-slide-toggle>
        </div>

        <app-price
          [amount]="userPaymentForm.value.amount"
          [includeTransactionFee]="userPaymentForm.value.includeTransactionFee"
          [initialValues]="initialValues"
        ></app-price>
      </ng-container>

      <mat-form-field>
        <mat-label>{{ 'table.payment_status' | translate }}</mat-label>
        <mat-select formControlName="status">
          <mat-option [value]="PaidStatus.NotPaid">{{ 'payment.not_paid' | translate }}</mat-option>
          <mat-option *ngIf="isOptional || paidStatus === PaidStatus.Declined" [value]="PaidStatus.Declined">
            {{ 'payment.declined' | translate }}
          </mat-option>
          <mat-option [value]="PaidStatus.Exempted">{{ 'payment.exempted' | translate }}</mat-option>
          <mat-option [value]="PaidStatus.PaidManually">{{ 'payment.paid_manually' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <app-expand-y
        *ngIf="userPaymentForm.value.status === PaidStatus.PaidManually"
        class="ns-expandable-field-wrapper"
      >
        <mat-form-field class="ns-expandable-field ns-no-gap">
          <mat-label>{{ 'economy.payment_date' | translate }}</mat-label>
          <input matInput type="date" formControlName="chargeDate" [max]="$any(today)" />
          <mat-icon matPrefix>calendar_today</mat-icon>
        </mat-form-field>
      </app-expand-y>

      <mat-form-field>
        <mat-label>{{ 'economy.payment_note' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="note"
          [rows]="2"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
        ></textarea>
      </mat-form-field>
    </form>
  </div>
</app-overlay>
